/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Color from 'color';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// import Dropdown from 'components/global/Dropdown';
import Close from 'components/icon/Close';

function Topic({ showTopic, handleClick }) {
  const {
    layoutData: {
      title = '{dropdownInfo.title}',
      content = '{dropdownInfo.content}',
      // subtitle = '{dropdownInfo.subtitle}',
      insert_ts = undefined,
      last_ts = undefined,
    } = {},
  } = useSelector((state) => state.map);
  const insertTs = insert_ts
    ? insert_ts.split('-')[0]
    : format(Date.now(), 'yyyy');
  const lastTs = last_ts ? last_ts.split('-')[0] : format(Date.now(), 'yyyy');

  return (
    <Wrap className={showTopic ? 'active' : null}>
      <TitleWrap>
        <Text>
          {insert_ts === lastTs ? insertTs : `${insertTs} - ${lastTs}`}
        </Text>

        <Title>{title}</Title>

        <TopicClose onClick={handleClick} />
      </TitleWrap>

      <Content>
        <Text>{content}</Text>
      </Content>
    </Wrap>
  );
}

export default Topic;

Topic.propTypes = {
  showTopic: PropTypes.bool,
  handleClick: PropTypes.func,
};

Topic.defaultProps = {
  showTopic: false,
  handleClick: undefined,
};

const Wrap = styled.div`
  position: absolute;
  width: 336px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  &.active {
    position: static;
    opacity: 1;
    pointer-events: auto;
  }
`;

const TitleWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 8px;
  color: #241916;
  background-color: ${({ theme }) => Color(theme.primary).alpha(0.7).string()};
`;

const Content = styled.div`
  min-width: 100%;
  padding: 8px;
  background-color: ${({ theme }) =>
    Color(theme.background).alpha(0.7).string()};
`;

const Title = styled.h2`
  font-size: 21px;
  line-height: 31px;
  margin: 0;
`;

const Text = styled.p`
  font-weight: 100;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
`;

const TopicClose = styled(Close)`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 28px;
  height: 28px;
  > i {
    &::before,
    &::after {
      background-color: ${({ theme }) => theme.background};
    }
  }
`;
